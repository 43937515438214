<template>
  <div class="uk-margin-large-top bg-login">
    <div class="bg-front" />
    <div class="uk-card uk-card-default uk-card-body login-card">
      <div class="content-wrapper">
        <div class="logo-wrapper">
          <router-link
            class="uk-text-decoration-none"
            to="/login"
          >
            <img
              v-lazy="`${images}/icon/back-arrow.svg`"
              alt="icon arrow"
            >
            <h3 class="uk-display-inline uk-margin-remove-bottom-s">
              Masuk Akun
            </h3>
          </router-link>
        </div>
        <h1>Ganti Password</h1>
        <h2>Silakan buat password yang baru untuk menggantikan password yang lama.</h2>
        <form @submit.prevent="onSubmit">
          <div class="uk-margin uk-text-left">
            <h4>Password Baru</h4>
            <span class="input-wrapper">
              <div class="password-field">
                <input
                  id="password"
                  v-model="password"
                  v-validate="'required|min:8'"
                  name="password"
                  class="uk-input"
                  :type="passwordFieldType"
                  placeholder="Masukkan password..."
                  autocomplete="off"
                  :class="{'uk-form-danger': errors.has('password')}"
                >
                <img
                  v-lazy="`${images}/icon/${eyeIcon}`"
                  style="position: absolute"
                  alt=""
                  class="toggle-eye"
                  @click="passwordVisibility"
                >
              </div>
              <span
                v-show="errors.has('password')"
                class="uk-text-small uk-text-danger"
              >{{ errors.first('password') }}</span>
            </span>
          </div>
          <div class="uk-margin uk-text-left">
            <h4>Password Baru</h4>
            <span class="input-wrapper">
              <div class="password-field">
                <input
                  id="confirm_password"
                  v-model="confirmPassword"
                  v-validate="'required|min:8'"
                  name="confirm_password"
                  class="uk-input"
                  :type="confirmPasswordFieldType"
                  placeholder="Masukkan password..."
                  autocomplete="off"
                  :class="{'uk-form-danger': errors.has('confirm_password')}"
                >
                <img
                  v-lazy="`${images}/icon/${eyeIconConfirm}`"
                  style="position: absolute"
                  alt=""
                  class="toggle-eye"
                  @click="confirmPasswordVisibility"
                >
              </div>
              <span
                v-show="errors.has('confirm_password')"
                class="uk-text-small uk-text-danger"
              >{{ errors.first('confirm_password') }}</span>
            </span>
          </div>
          <button
            v-if="!is_loading"
            class="uk-button uk-button-primary uk-width-1-1"
            type="submit"
            :disabled="is_loading"
          >
            Konfirmasi
          </button>
          <button
            v-else
            class="uk-button uk-button-primary uk-width-1-1"
            type="button"
            disabled
          >
            <div uk-spinner />
          </button>
        </form>
      </div>
    </div>
    <modal-save-confirm />
  </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant'
import {
  notificationDanger
  // notificationSuccess
} from '@/utils/notification'
import ModalSaveConfirm from './ModalSaveConfirm'
import { mapActions } from 'vuex'

export default {
  components: {
    ModalSaveConfirm
  },
  data() {
    return {
      is_loading: false,
      password: '',
      confirmPassword: '',
      passwordFieldType: 'password',
      confirmPasswordFieldType: 'password',
      images: PREFIX_IMAGE,
      eyeIcon: 'eye.svg',
      eyeIconConfirm: 'eye.svg'
    }
  },
  methods: {
    ...mapActions({
      savePassword: 'auth/changePassword'
    }),
    passwordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
      this.eyeIcon = this.eyeIcon === 'eye.svg' ? 'eye-slash.svg' : 'eye.svg'
    },
    confirmPasswordVisibility() {
      this.confirmPasswordFieldType = this.confirmPasswordFieldType === 'password' ? 'text' : 'password'
      this.eyeIconConfirm = this.eyeIconConfirm === 'eye.svg' ? 'eye-slash.svg' : 'eye.svg'
    },
    onSubmit() {
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.password === this.confirmPassword) {
            if (this.$validator.errors.any()) return
            this.handleChange()
          } else {
            notificationDanger({message: 'Password Harus Sama'})
          }
        }
      })
    },
    async handleChange() {
      try {
        const pageURL = window.location.href
        const lastURLSegment = pageURL.substr(pageURL.lastIndexOf('/') + 1)
        this.is_loading = true
        await this.savePassword({
          id: lastURLSegment,
          password: this.password
        })
        const res = await this.$store.getters['auth/getChangePassword']
        if (res.message === 'OK') {
          window.UIkit.modal('#modal-save-confirm').show()
        } else {
          notificationDanger(`Something went wrong`)
        }
      } catch (error) {
        notificationDanger(error.response)
        console.log(error.response)
        this.is_loading = false
      }
    }
  }
}
</script>

<style scoped>

.logo-wrapper {
  display: flex;
  align-items: baseline;
  margin: 0 0 104px;
}
h1 {
  text-align: left;
  font-family: ClanOT;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  color: #025231;
}
h2 {
  text-align: left;
  font-family: ClanOT;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #1F2E28;
}
h3 {
  font-family: ClanOT;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  margin-top: 0;
}
h4 {
  font-family: ClanOT;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: left;
  color: #025231;
  margin-bottom: 5px;
}
.password-field {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.toggle-eye {
  position: absolute;
  margin-right: 10px;
}
.uk-margin-large-top {
  width: 100%;
  max-width: 100%;
  display: flex;
  margin-top: 0 !important;
}

</style>
