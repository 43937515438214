<template>
  <div
    id="modal-save-confirm"
    uk-modal
    esc-close="false"
    bg-close="false"
    container="#container-modal-add-user"
  >
    <div class="uk-modal-dialog uk-modal-body">
      <div
        class="uk-modal-title"
        style="color:#025231"
      >
        <img
          v-lazy="`${images}/icon/success.svg`"
          style="width: 24px; heigth: 24px"
          alt=""
        >
        Ganti Password Berhasil
      </div>
      <p style="font-size:15px">
        Silakan masuk dengan password Anda yang baru
      </p>
      <div style="padding-top:20px">
        <button
          class="uk-button uk-button-default"
          type="button"
          :disabled="is_loading"
          @click="handleSave"
        >
          Masuk Akun
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant'

export default {
  data() {
    return {
      is_loading: false,
      images: PREFIX_IMAGE
    }
  },
  methods: {
    handleSave () {
      window.location.href='/login'
    }
  }
}
</script>

<style scoped>
.uk-modal-dialog {
  width: 460px;
  height: 198px;
  padding: 24px 24px;
}
.uk-modal-title {
  font-family: ClanOT;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
  color: #049559;
}
.uk-button {
  border: 1px solid #025231;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: normal;
  font-size: 14px;
  color: #025231;
}
</style>
